<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>Promo Code Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        :slot="sm ? 'extension' : null"
        color="color3"
        append-icon="fas fa-search"
        label="Search"
        hide-details
        v-model="search"
        solo-inverted
        clearable :dark="color1IsDark"
        :class="{'mb-3': sm}"
      ></v-text-field>
      <v-btn color="color3Text color3--text" fab small @click.stop="newPromo">
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="promoCodes"
      :options.sync="pagination"
      item-key="id"
      :loading="loading"
      :search="search"
      :show-select="doDelete"
      v-model="selected"
    >
      <template v-slot:progress>
        <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <div class="subheading">{{item.code}}</div>
        {{item.description}}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.color">
          {{item.status}}
          <v-icon v-if="item.auto" small class="ml-2">fas fa-magic</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.maxUses`]="{ item }">
        <span v-if="item.maxUses">{{item.maxUses}}</span>
        <v-icon v-else>fas fa-infinity</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="color3 color3Text--text"
          small fab
          @click="select(item)"
        >
          <v-icon small>fas fa-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-container class="pa-0">
      <v-expand-transition>
        <div v-if="selected.length">
          <v-row dense class="px-4">
            <v-col cols="12" class="subheading">
              What would you like to do with the selected promos?
            </v-col>
            <v-col cols="12">
              <v-btn
                color="color3 color3Text--text"
                class="mr-2"
                @click.stop="disableSelected"
              >Disable</v-btn>
              <v-btn
                color="error white--text"
                @click.stop="deleteSelected"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row dense justify="end">
        <v-btn color="color3" text class="xsmall" icon @click.stop="asyncData" :loading="loading">
          <v-icon>fas fa-redo-alt</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <!-- DETAILS DRAWER -->
    <promo-details
      ref="detailDrawer"
      :promo="selectedPromo"
      :existing="promos"
      @save-complete="onSaveComplete"
      :username="username"
    ></promo-details>
    <!-- Confirm-->
    <vbl-confirm-dialog
      :ask="confirmDialog"
      @confirm="() => { if(confirmAction) confirmAction() }"
      @deny="confirmDialog = false"
      :loading="loading"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import PromoDetails from './DetailsDrawer'
import Promo from '@/classes/Promo'

export default {
  props: ['active', 'username', 'existing'],
  data () {
    return {
      promos: [],
      loading: false,
      search: null,
      headers: [
        { text: 'Code', value: 'code', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Used', value: 'uses', align: 'center' },
        { text: 'Max Uses', value: 'maxUses', align: 'center' },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ],
      persistDialog: false,
      selectedPromo: null,
      pagination: { itemsPerPage: 10 },
      selected: [],
      doDelete: true,
      confirmDialog: false,
      confirmAction: null
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo', 'color1IsDark']),
    sm () {
      return this.$vuetify.breakpoint.smAndDown
    },
    promoCodes () {
      return this.promos.map(m => new Promo(m))
    },
    organization () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    select (promo) {
      this.selectedPromo = promo
      this.$refs.detailDrawer.open()
    },
    asyncData () {
      this.loading = true
      this.$VBL.cart.promos.getAll(this.username)
        .then(r => { this.promos = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    newPromo () {
      this.select(new Promo({ organizationId: this.organization.id }))
    },
    onSaveComplete (dto) {
      var e = this.promoCodes.find(f => f.id === dto.id)
      if (e) {
        e.update(dto)
      } else {
        this.promos.push(dto)
      }
    },
    deleteSelected () {
      this.confirmAction = this.performDelete
      this.confirmDialog = true
    },
    disableSelected () {
      this.confirmAction = this.performDisable
      this.confirmDialog = true
    },
    performDisable () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.cart.promos.disable(this.username, ids)
        .then(r => {
          this.selected.forEach(f => {
            f.disabled = true
          })
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    },
    performDelete () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.cart.promos.delete(this.username, ids)
        .then(r => {
          this.promos = this.promos.filter(f => !ids.includes(f.id))
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.asyncData()
      }
    }
  },
  components: {
    PromoDetails
  },
  mounted () {
    this.asyncData()
  }
}
</script>

<style>

</style>
