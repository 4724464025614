import moment from 'moment'
import { usDollars } from '@/Filters'

export default class Promo {
  id = 0
  organizationId = 0
  product = 'Tournament Registration'
  code = null
  discountAmount = null
  discountPercentage = null
  tournamentIds = null
  divisionIds = null
  playerIds = null
  userIds = null
  maxUses = null
  uses = 0
  dtExpires = null
  disabled = false
  deleted = false
  types = null

  constructor (dto) {
    if (dto) this.update(dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  // Getter Setters
  get dtCreated () {
    return moment(this._dtCreated)
  }

  set dtCreated (val) {
    this._dtCreated = val
  }

  get myTypes () {
    return this.types ? this.types.split(',') : []
  }

  set myTypes (val) {
    this.types = val ? Array.isArray(val) ? val.join(',') : val : null
  }

  // Getters
  get description () {
    return `${(this.discountAmount ? usDollars(this.discountAmount) : `${this.discountPercentage * 100}%`)} Off ${this.product}`
  }

  get expires () {
    return this.dtExpires && moment(this.dtExpires)
  }

  get expired () {
    return this.expires && !this.expires.isAfter()
  }

  get usedUp () {
    return this.maxUses && this.uses >= this.maxUses
  }

  get status () {
    return this.disabled ? 'Disabled' : this.expired ? 'Expired' : this.usedUp ? 'Used' : 'Active'
  }

  get color () {
    return `${this.status === 'Active' ? 'success' : 'error'} white--text`
  }

  get discountValue () {
    return this.discountAmount || (this.discountPercentage * 100)
  }

  get discountType () {
    return this.discountPercentage > 0 ? 'Percent' : 'Dollars'
  }

  get isAdvanced () {
    return this.tournamentIds || this.divisionIds || this.playerIds || this.userIds || this.myTypes.length > 0
  }

  get dto () {
    return {
      id: this.id,
      organizationId: this.organizationId,
      product: this.product,
      code: this.code,
      discountAmount: +this.discountAmount,
      discountPercentage: +this.discountPercentage,
      tournamentIds: this.tournamentIds,
      divisionIds: this.divisionIds,
      playerIds: this.playerIds,
      userIds: this.userIds,
      maxUses: this.maxUses,
      uses: this.uses,
      dtExpires: this.dtExpires && this.expires.isValid() ? this.dtExpires : null,
      disabled: this.disabled,
      deleted: this.deleted,
      auto: this.auto,
      types: this.types
    }
  }
}
