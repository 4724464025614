<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
       <v-alert type="error" :value="showAlert" text prominent>
        The Promo Code Manager is moving to the Cart Manager
       </v-alert>
      </v-col>
      <v-col cols="12">
       <promo-manager :username="username" :active="true"></promo-manager>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PromoManager from '@/components/Cart/Manager/Promos/Manager'

export default {
  props: ['username'],
  computed: {
    showAlert () {
      return this.$route.name === 'organization-promos'
    }
  },
  components: {
    PromoManager
  }
}
</script>

<style>

</style>
