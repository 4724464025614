<template>
  <v-navigation-drawer
    right
    v-model="isOpen"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
    :permanent="editing"
  >
    <!-- HEADER -->
    <template v-slot:prepend>
    <v-toolbar color="color2 color2Text--text" tile dense>
      <v-toolbar-title>Promo: {{myPromo ? myPromo.code : ''}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color1 color1Text--text"
        fab
        small
        @click.stop="cancel(true)"
      >
        <v-icon small>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    </template>
    <!-- FOOTER -->
    <template v-slot:append>
      <v-btn
        v-if="myPromo && !myPromo.isAdvanced"
        color="color3"
        text class="xsmall"
        @click.stop="advanced = !advanced"
      >
        {{advanced ? 'simple' : 'advanced'}}
      </v-btn>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="activeBtn.color"
              :disabled="activeBtn.disabled"
              :loading="activeBtn.loading"
              @click.stop="activeBtn.onClick"
              block
              class="py-9"
            >
              {{ activeBtn.text }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error white--text"
              @click.stop="activeBtn.cancel"
              :disabled="activeBtn.loading"
              block
              class="py-9"
            >
            {{activeBtn.cancelText || 'Close'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
    <!-- BODY -->
    <v-card-text v-if="myPromo" :class="{ 'px-0': window === 1 }">
      <v-container class="pt-10 px-0">
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-row dense>
              <v-col cols="12" class="text-end" v-if="myPromo && myPromo.uses">
                <v-btn
                  color="color3"
                  text
                  @click="window=1"
                  small
                >
                  View Usage
                  <v-icon class="ml-2">fas fa-caret-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="caption" v-if="!isNew">
                Id: {{myPromo.id}} | Created: {{myPromo.dtCreated ? myPromo.dtCreated.format('LLLL') : 'NA'}} By: {{myPromo.createdBy}}
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Promo Code"
                  v-model="myPromo.code"
                  :disabled="!editing || !!myPromo.uses"
                  :hint="editing && myPromo.uses ? 'This can not be edited once it has been used' : ''"
                  :error-messages="codeErrors"
                  @blur="$v.myPromo.code.$touch()"
                  persistent-hint
                  color="color3"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <!-- DISCOUNT -->
                <v-text-field
                  label="Discount"
                  v-model="discountValue"
                  type="number"
                  :disabled="!editing || !!myPromo.uses"
                  :hint="editing && myPromo.uses ? 'This can not be edited once it has been used' : ''"
                  persistent-hint
                  @blur="$v.discountValue.$touch()"
                  :error-messages="amountErrors"
                  color="color3"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="['Dollars', 'Percent']"
                  v-model="discountType"
                  label="Type"
                  :disabled="!editing || !!myPromo.uses"
                  :hint="editing && myPromo.uses ? 'This can not be edited once it has been used' : ''"
                  persistent-hint
                  color="color3"
                  item-color="color3"
                ></v-select>
              </v-col>
              <!-- MAX USES -->
              <v-col cols="12">
                <v-text-field
                  label="Max Uses"
                  v-model="myPromo.maxUses"
                  hint="Enter a number here to limit the number of time this promo can be used"
                  type="number"
                  persistent-hint
                  :disabled="!editing"
                  :error-messages="maxErrors"
                  color="color3"
                ></v-text-field>
              </v-col>
            </v-row>
            <date-time-picker
              :datetime.sync="myPromo.dtExpires"
              label="Expiry"
              :disabled="!editing"
            ></date-time-picker>
            <v-row dense>
              <v-col cols="12">
                <v-checkbox
                  label="Disabled"
                  v-model="myPromo.disabled"
                  hide-details
                  :disabled="!editing"
                  color="color3"
                ></v-checkbox>
              </v-col>
            </v-row>
            <!-- ADVANCED -->
            <v-expand-transition>
              <div v-if="advanced || (myPromo && myPromo.isAdvanced)">
                <v-row dense>
                  <v-col cols="12">
                    <v-alert type="info" :value="true">
                      Your promos can be restricted to specific, tournament, divisions, players, or users. Please contact Volleyball Life support for help with this.
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      label="Restrict To These Item Types"
                      :items="prodTypes"
                      v-model="myPromo.myTypes"
                      :disabled="!editing"
                      hint="This promo will only apply to these item types"
                      persistent-hint
                      chips multiple deletable-chips
                      color="color3"
                      item-color="color3"
                      :menu-props="{ closeOnContentClick: true }"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Tournament Ids"
                      v-model="myPromo.tournamentIds"
                      :disabled="!editing"
                      hint="This promo will only apply to these tournaments"
                      persistent-hint
                      color="color3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Division Ids"
                      v-model="myPromo.divisionIds"
                      :disabled="!editing"
                      hint="This promo will only apply to these divisions"
                      color="color3"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Required Tournament Ids"
                      v-model="myPromo.requiredTournamentIds"
                      :disabled="!editing"
                      hint="This promo will only apply in combination with a registration to 1 of these tournaments"
                      persistent-hint
                      color="color3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Required Division Ids"
                      v-model="myPromo.requiredDivisionIds"
                      :disabled="!editing"
                      hint="This promo will only apply in combination with a registration to 1 of these divisions"
                      color="color3"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Player Ids"
                      v-model="myPromo.playerIds"
                      :disabled="!editing"
                      hint="This promo will be limited to these players"
                      persistent-hint
                      color="color3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="User Ids"
                      v-model="myPromo.userIds"
                      :disabled="!editing"
                      hint="This promo will be limited to these users"
                      persistent-hint
                      color="color3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      label="Automatically Apply Promo"
                      :disabled="!editing"
                      v-model="myPromo.auto"
                      color="success"
                    ></v-switch>
                    <v-alert type="warning" v-if="myPromo.auto" prominent transition="scale-transition">
                      This promo will be automatically applied in the cart without the user entering the code
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-if="error">
                <v-alert type="error" :value="true">
                  Oops: {{error}}
                </v-alert>
              </div>
            </v-expand-transition>
          </v-window-item>
          <v-window-item :key="1">
            <v-row dense>
              <v-col cols="12">
                <v-btn
                  color="color3"
                  text
                  @click="window=0"
                  small
                >
                  <v-icon class="mr-2">fas fa-caret-left</v-icon>
                  Back to details
                </v-btn>
              </v-col>
              <v-col cols="12" class="pl-9 text-h5">
                Usage History
              </v-col>
              <v-col cols="12">
                <loading-bar :value="loading"></loading-bar>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in usage"
                        :key="item.name"
                      >
                        <td>
                          <table>
                            <tr>
                              <td class="caption grey--text">Date: {{ item.dtCreated | localDt }}</td>
                              <td class="caption text-end">Order Id: <router-link class="color3--text" :to="{ name: 'order-details', params: {orderId: item.orderId} }">{{ item.orderId }}</router-link></td>
                            </tr>
                            <tr>
                              <td colspan="2">{{ item.description }}</td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-container>
    </v-card-text>

  </v-navigation-drawer>
</template>

<script>
import Promo from '@/classes/Promo'
import { mapGetters } from 'vuex'
import DateTimePicker from '@/components/Utils/DateTimePicker'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: ['promo', 'existing', 'username'],
  data () {
    return {
      isOpen: false,
      editing: false,
      iPromo: {},
      fab: null,
      saving: false,
      iDiscountValue: null,
      iDiscountType: null,
      advanced: false,
      error: null,
      window: 0,
      loading: false,
      usage: []
    }
  },
  validations: {
    myPromo: {
      code: { required }
    },
    discountValue: { required }
  },
  computed: {
    ...mapGetters(['user']),
    codeErrors () {
      const errors = []
      if (!this.$v.myPromo.code.$dirty) return errors
      !this.$v.myPromo.code.required && errors.push('A code is required')
      this.taken && errors.push('You cannot have to promos with the same code')
      return errors
    },
    amountErrors () {
      const errors = []
      if (!this.$v.discountValue.$dirty) return errors
      !this.discountValue && errors.push('An amount is required')
      return errors
    },
    maxErrors () {
      const errors = []
      if (!this.myPromo.maxUses) return errors
      this.myPromo.maxUses && this.myPromo.maxUses < this.myPromo.uses && errors.push('Can\'t set the max below current uses')
      return errors
    },
    taken () {
      return this.iPromo.code !== this.promo.code && this.existing && this.existing.filter(f => f && f.id !== this.myPromo.id).map(m => m.code).includes(this.myPromo.code)
    },
    isNew () {
      return this.promo && !this.promo.id
    },
    myPromo () {
      return this.editing ? this.iPromo : this.promo
    },
    discountValue: {
      get: function () {
        return this.myPromo && this.myPromo.discountValue
      },
      set: function (val) {
        this.iDiscountValue = val
        this.setDiscount()
      }
    },
    discountType: {
      get: function () {
        return this.myPromo.discountType
      },
      set: function (val) {
        this.iDiscountType = val
        this.setDiscount()
      }
    },
    activeBtn () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        text: 'Edit',
        cancel: () => { this.cancel(true) },
        loading: this.loading || this.saving
      } : {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.onSaveClick,
        text: 'save',
        cancelText: 'Cancel',
        cancel: this.cancel,
        disabled: !this.dirty,
        loading: this.saving
      }
    },
    dirty () {
      return JSON.stringify(this.promo) !== JSON.stringify(this.iPromo)
    },
    valid () {
      return this.myPromo.code && this.discountValue && !this.taken && !this.maxErrors.length
    },
    dto () {
      return this.myPromo.dto
    },
    prodTypes () {
      return [
        { text: 'Memberships', value: 'MEM' },
        // { text: '', value: 'OPAY' },
        { text: 'Player Additions', value: 'PA' },
        { text: 'Products', value: 'PROD' },
        { text: 'Registrations', value: 'TR' }
      ]
    }
  },
  methods: {
    onSaveClick () {
      this.$v.$touch()
      if (!this.valid) return
      this.saving = true
      this.$VBL.cart.promos.post(this.myPromo)
        .then(r => {
          this.$emit('save-complete', this.myPromo)
          this.cancel()
        })
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.saving = false })
    },
    edit () {
      this.iPromo = new Promo(JSON.parse(JSON.stringify(this.promo)))
      this.iDiscountValue = this.iPromo.discountValue
      this.iDiscountType = this.iPromo.discountType
      this.addDiscount = false
      this.editing = true
    },
    cancel (close) {
      this.editing = false
      this.advanced = false
      this.iPromo = {}
      this.$v.$reset()
      if (this.isNew || close === true) this.isOpen = false
      this.usage = []
      this.window = 0
    },
    setDiscount () {
      if (this.iDiscountType === 'Dollars') {
        this.iPromo.discountAmount = +this.iDiscountValue
        this.iPromo.discountPercentage = null
      } else {
        this.iPromo.discountAmount = null
        this.iPromo.discountPercentage = +this.iDiscountValue / 100
      }
    },
    open () {
      this.isOpen = true
    },
    getUsage () {
      this.loading = true
      this.$VBL.cart.promos.getUsage(this.username, this.promo.id)
        .then(r => { this.usage = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    isOpen: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.isNew && this.edit()
        if (this.myPromo && this.myPromo.isAdvanced) this.advanced = true
      }
    },
    editing: function (val) {
      this.$emit('editing-change', val)
    },
    'myPromo.code': function (val) {
      if (val) {
        this.myPromo.code = this.myPromo.code.toUpperCase()
      }
    },
    myPromo: {
      handler: function (val) {
        this.error = null
        if (val && val.code) {
          this.myPromo.code = this.myPromo.code.toUpperCase()
        }
      },
      deep: true
    },
    window: function (val) {
      if (val === 1) this.getUsage()
    }
  },
  mounted () {
    this.isNew && this.edit()
  },
  components: {
    DateTimePicker
  }
}
</script>
